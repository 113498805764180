<template>
  <div>
    <FullCalendar ref="fullCalendar" :options="calendarOptions" />

    <template v-if="this.$store.state.showMonoPopupConduite">
      <MonoModal
        visible="true"
        :moniteurs="this.moniteurs"
        @getLesMoniteurs="getLesMoniteurs"
        :monoChecked="moniteurchoix"
        v-on:moniteurChoicePlanning="moniteurChoicePlanning"
        :choixMonoSaves="choixMonoSaves"
      />
    </template>

    <Dialog
      v-model:visible="resaModal"
      modal
      header="Réservation"
      :style="{ width: '80rem' }"
    >
      <div class="head">
        <p>{{ resaHeader }}</p>
        <select
          class="monoSelectResaEleves"
          name="moniteurs"
          id="mono"
          :value="monoChoixResa"
        >
          <template v-for="m in moniteurs" :key="m.id">
            <template v-if="m.idMono == monoChoixResa">
              <option :value="m.idMono" class="selectMono">
                {{ m.fullname }}
              </option>
            </template>
            <template v-else>
              <option :value="m.idMono">{{ m.fullname }}</option>
            </template>
          </template>
        </select>
        <p>Boite : {{ boiteResa }}</p>

        <label>
          Tous les élèves
          <input
            type="checkbox"
            name="allStudent"
            id="allStudent"
            @click="getAllEleves"
          />
        </label>
      </div>
      <div class="table">
        <template v-if="showEleveDispo">
          <DataTable
            :value="eleveDispos"
            tableStyle="width: 100%"
            paginator
            :rows="5"
            :rowsPerPageOptions="[5, 10, 20, 50]"
          >
            <Column field="code" header="">
              <template #body="{ data }">
                <input
                  type="radio"
                  name="eleve"
                  :id="data.id"
                  :value="data.id"
                  @click="getLieuEleve(data)"
                  v-model="idEleveChoixResa"
                />
              </template>
            </Column>
            <Column field="nom" header="Nom"></Column>
            <Column field="prenom" header="Prénom"></Column>
            <Column field="telephone" header="Tel"></Column>
            <Column field="dossier.adresse.ville" header="Ville"></Column>
            <Column field="solde" header="Solde"></Column>
            <Column field="category" header="Lieux Début"></Column>
            <Column field="boiteNom" header="Boite" sortable></Column>
            <Column field="dispo" header="Dispo 2H"></Column>
          </DataTable>
        </template>
        <template v-else>
          <DataTable :value="lesEleves" tableStyle="width: 100%" :rows="10">
            <Column field="code" header="">
              <template #body="{ data }">
                <input
                  type="radio"
                  name="eleve"
                  :id="data.id"
                  :value="data.id"
                  @click="getLieuEleve(data)"
                  v-model="idEleveChoixResa"
                />
              </template>
            </Column>
            <Column field="nom" header="Nom"></Column>
            <Column field="prenom" header="Prénom"></Column>
            <Column field="telephone" header="Tel"></Column>
            <Column field="dossier.adresse.ville" header="Ville"></Column>
            <Column field="solde" header="Solde"></Column>
            <Column field="category" header="Lieux Début"></Column>
            <Column field="boiteNom" header="Boite" sortable></Column>
            <Column field="quantity" header="Dispo 2H"></Column>
          </DataTable>
          <Paginator
            :rows="10"
            :totalRecords="countElevesList"
            @page="onPaginate"
          ></Paginator>
        </template>
      </div>
      <template v-if="idEleveChoixResa != null">
        <template v-if="this.eleveChoixResa.choixDispo != 'null'">
          <template v-if="this.eleveChoixResaMonoSelect == true">
            <select
              name="debLieu"
              id="debLieu"
              @click="getLieuFin"
              class="resaModalEleves"
              v-model="lieuDebChoix"
            >
              <option>Lieu de début</option>
              <template v-for="l in lieuxDeb" :key="l.id">
                <template v-if="l.choixEleve == true">
                  <option :value="l.id" :title="l.fulladresse">
                    {{ l.nom }}
                  </option>
                </template>
              </template>
            </select>

            <template v-if="lieuxFin != null">
              <select
                name="finLieu"
                id="finLieu"
                v-model="lieuFinChoix"
                class="resaModalEleves"
              >
                <option>Lieu de retour</option>
                <template v-for="l in lieuxFin" :key="l.id">
                  <template v-if="l.choixEleve == true">
                    <option :value="l.id" :title="l.fulladresse">
                      {{ l.nom }}
                    </option>
                  </template>
                </template>
              </select>
            </template>
          </template>

          <template v-else>
            <h4>
              L'élève sélectionné n'a pas choisi ce moniteur pour ses cours de
              conduite, il doit mondifier son choix pour inclure ce moniteur
            </h4>
          </template>
        </template>

        <template v-else>
          <h4>
            L'élève sélectionné doit choisir ses moniteurs et lieux de
            rendez-vous avant de pouvoir réserver un cours de conduite
          </h4>
        </template>
      </template>
      <br />
      <br />
      <br />

      <template v-if="lieuFinChoix != null">
        <template v-if="idEleveChoixResa != null">
          <template v-if="loadResaModalBtn == false">
            <button class="btn btn-primary btn-sm" @click="setApointment">
              VALIDER
            </button>
          </template>
          <template v-else>
            <button
              @click="setApointment()"
              class="btn btn-primary btn-sm"
              disabled
            >
              <span><i class="fa fa-spinner fa-spin"></i>VALIDER</span>
            </button>
          </template>
        </template>
      </template>
    </Dialog>

    <Dialog
      v-model:visible="resaPopupEleve"
      modal
      :header="'Réservation : ' + resaHeader"
      :style="{ width: '60rem' }"
    >
      <template v-if="apSimu == true">
        <template v-for="l in lieuxDeb" :key="l.id">
          <h5>Voulez-vous réserver un cours sur simulateur à : {{ l.nom }}</h5>
          <br />
          <button
            class="btn btn-primary btn-sm"
            @click="
              this.lieuDebChoix = l.id;
              this.lieuFinChoix = l.id;
              setApointment();
            "
            v-if="popupMotif == false"
          >
            VALIDER
          </button>
        </template>
      </template>

      <template v-else>
        <div class="headModal">
          <h5>
            BA = {{ this.prixHeure.prixBa }}€ BM ET Simulateur =
            {{ this.prixHeure.prixBm }}€ ----- Type : {{ this.boiteResa }}
          </h5>
          <h5>{{ this.boiteResa }}</h5>
          <br />
        </div>
        <select
          name="debLieu"
          id="debLieu"
          class="selectResaEleve"
          @click="getLieuFin"
          v-model="lieuDebChoix"
        >
          <option>Lieu de début</option>
          <template v-for="l in lieuxDeb" :key="l.id">
            <template v-if="popupMotif == true">
              <template v-if="lieuPrevAp[0] == ' ' + l.nom">
                <template v-if="l.choixEleve == true">
                  <option
                    style="color: blue"
                    :value="l.id"
                    :title="l.fulladresse"
                  >
                    {{ l.nom }}
                  </option>
                </template>
              </template>
              <template v-else>
                <template v-if="l.choixEleve == true">
                  <option :value="l.id" :title="l.fulladresse">
                    {{ l.nom }}
                  </option>
                </template>
              </template>
            </template>
            <template v-else>
              <template v-if="l.choixEleve == true">
                <option :value="l.id" :title="l.fulladresse">
                  {{ l.nom }}
                </option>
              </template>
            </template>
          </template>
        </select>
        <template v-if="lieuxFin != null">
          <select
            name="finLieu"
            id="finLieu"
            class="selectResaEleve"
            v-model="lieuFinChoix"
          >
            <option>Lieu de retour</option>
            <template v-for="l in lieuxFin" :key="l.id">
              <template v-if="popupMotif == true">
                <template v-if="lieuPrevAp[1] == l.nom">
                  <template v-if="l.choixEleve == true">
                    <option
                      style="color: blue"
                      :value="l.id"
                      :title="l.fulladresse"
                    >
                      {{ l.nom }}
                    </option>
                  </template>
                </template>
                <template v-else>
                  <template v-if="l.choixEleve == true">
                    <option :value="l.id" :title="l.fulladresse">
                      {{ l.nom }}
                    </option>
                  </template>
                </template>
              </template>
              <template v-else>
                <template v-if="l.choixEleve == true">
                  <option :value="l.id" :title="l.fulladresse">
                    {{ l.nom }}
                  </option>
                </template>
              </template>
            </template>
          </select>
        </template>
        <br /><br />
        <template v-if="lieuFinChoix != null">
          <template v-if="loadResaModalBtn == false">
            <button
              class="btn btn-primary btn-sm"
              @click="setApointment"
              v-if="popupMotif == false"
            >
              VALIDER
            </button>
          </template>
          <template v-else>
            <button
              @click="setApointment()"
              class="btn btn-primary btn-sm"
              disabled
            >
              <span><i class="fa fa-spinner fa-spin"></i>VALIDER</span>
            </button>
          </template>
          <template v-if="loadModifBtn == false">
            <button
              class="btn btn-primary btn-sm"
              @click="putApointment"
              v-if="popupMotif == true"
            >
              MODIFIER
            </button>
          </template>
          <template v-else>
            <button
              @click="putApointment()"
              v-if="popupMotif == true"
              class="btn btn-primary btn-sm"
              disabled
            >
              <span><i class="fa fa-spinner fa-spin"></i>MODIFIER</span>
            </button>
          </template>
        </template>
      </template>
    </Dialog>

    <Dialog
      v-model:visible="popupMotif"
      modal
      header="Motif d'annulation du cours"
      :style="{ width: '50rem' }"
    >
      <select
        name="typeMotf"
        id="typeMotf"
        v-model="motif.leTypeMotif"
        class="selectResaEleve"
      >
        <option value="null">Saisir le type de motif</option>
        <template v-for="t in typeMotif" :key="t.id">
          <option :value="t">{{ t.label }}</option>
        </template>
      </select>
      <input
        type="text"
        placeholder="Saisir un commentaire"
        id="motifText"
        v-model="motif.com"
      />
      <br /><br />
      <template v-if="motif.leTypeMotif != null">
        <template v-if="motif.com != ''">
          <button
            class="btn btn-primary btn-sm btnValMotif"
            @click="setMotif"
            v-if="loadCancelResa == false"
          >
            VALIDER
          </button>

          <button
            class="btn btn-primary btn-sm btnValMotif"
            @click="setMotif"
            v-if="loadCancelResa == true"
            disabled
          >
            <span><i class="fa fa-spinner fa-spin"></i>VALIDER</span>
          </button>
        </template>
      </template>
      <button class="btn btn-info btn-sm" @click="showEditModal">
        MODIFIER
      </button>
    </Dialog>
  </div>
</template>

<script>
import "@fullcalendar/core/vdom"; // solves problem with Vite
import FullCalendar from "@fullcalendar/vue3";
import interactionPlugin from "@fullcalendar/interaction";
import timeGridPlugin from "@fullcalendar/timegrid";
import resourceTimeGridPlugin from "@fullcalendar/resource-timegrid";
import MonoModal from "@/components/agence/planning/modals/MonoModal.vue";
import moment from "moment";

export default {
  props: [
    "eleves",
    "countEleves",
    "moniteurs",
    "getEleves",
    "getMoniteurs",
    "getMoniteursChange",
  ],
  components: {
    FullCalendar,
    MonoModal,
  },

  data() {
    return {
      countElevesList: 0,
      countElevesDispo: 0,
      lesEleves: [],
      apSimu: false,
      loadResaModalBtn: false,
      loadModifBtn: false,
      resaPopupEleve: false,
      motif: {
        leTypeMotif: null,
        com: "",
      },
      firstCours: false,
      boiteResa: null,
      firstCoursFin: [],
      popupMotif: false,
      typeMotif: [],
      showEleveDispo: true,
      eleveDispos: [],
      idEleveChoixResa: null,
      lieuDebChoix: null,
      lieuFinChoix: null,
      lieuxDeb: [],
      lieuxFin: null,
      prixHeure: 0,
      monoChoixResa: null,
      resaModal: false,
      hideSundayBtn: false,
      calendarOptions: {
        plugins: [timeGridPlugin, interactionPlugin, resourceTimeGridPlugin],
        initialView: "resourceTimeGridWeek",
        themeSystem: "bootstrap",
        selectable: true,
        nowIndicator: true,
        navLinks: true,
        firstDay: 1,
        allDaySlot: true,
        slotMinTime: "07:00:00",
        slotMaxTime: "20:00:00",
        slotDuration: "00:30:00",
        height: "auto",
        datesAboveResources: true,
        locale: "fr",
        timeZone: "local",
        editable: true,
        eventResizableFromStart: true,
        customButtons: {
          hideSunday: {
            text: "Dimanche",
            click: (e) => this.hideSundayMethod(e),
            background: "red",
          },
          prev: {
            // this overrides the prev button
            text: "PREV",
            click: () => {
              this.todayBtnActive = false;
              let calendarApi = this.$refs.fullCalendar.getApi();
              calendarApi.prev();
              let date = moment(calendarApi.getDate().toString()).format(
                "YYYY-MM-DDTHH:mm:ss"
              );
              if(this.dayWeekPlanning == false){
                  this.$emit("getMoniteursChange", date, false);
              }else{
                this.$emit("getMoniteursChange", date, true);
              }

              let resultIndispo = this.calendarOptions.events.filter(
                (e) => e.type != "indispo"
              );
              this.calendarOptions.events = resultIndispo;

                if (this.dayWeekPlanning == false) {
                  if (this.calendarOptions.events != null) {
                    this.calendarOptions.events.forEach((e) => {
                      if (e.type == "boite") {
                          if (
                            calendarApi.getDate().getDay() == 0
                          ) {
                            let start = moment(e.start).subtract(1, "week");
                            let end = moment(e.end).subtract(1, "week");
                            e.start = start.format("YYYY-MM-DDTHH:mm:ss");
                            e.end = end.format("YYYY-MM-DDTHH:mm:ss");
                          }else{
                            if(this.nextBtnPress){
                              let start = moment(e.start).subtract(1, "week");
                              let end = moment(e.end).subtract(1, "week");
                              e.start = start.format("YYYY-MM-DDTHH:mm:ss");
                              e.end = end.format("YYYY-MM-DDTHH:mm:ss");
                              this.nextBtnPress = false;
                            }
                          }
                      }
                    });
                  }
                } else {
                  if (this.calendarOptions.events != null) {
                    this.calendarOptions.events.forEach((e) => {
                      if (e.type == "boite") {
                        let start = moment(e.start).subtract(1, "week");
                        let end = moment(e.end).subtract(1, "week");
                        e.start = start.format("YYYY-MM-DDTHH:mm:ss");
                        e.end = end.format("YYYY-MM-DDTHH:mm:ss");
                      }
                    });
                  }
                }

            },
          },
          next: {
            // this overrides the next button, essayer de récupérer la semaine afficher suite auc lique du bouton next
            text: "NEXT",
            click: () => {
              this.todayBtnActive = false;
              this.nextBtnPress = true;
              let calendarApi = this.$refs.fullCalendar.getApi();
              calendarApi.next();
              let date = moment(calendarApi.getDate().toString()).format(
                "YYYY-MM-DDTHH:mm:ss"
              );

              if(this.dayWeekPlanning == false){
                this.$emit("getMoniteursChange", date, false);
              }else{
                this.$emit("getMoniteursChange", date, true);
              }

              let resultIndispo = this.calendarOptions.events.filter(
                (e) => e.type != "indispo"
              );
              this.calendarOptions.events = resultIndispo;

                if (this.dayWeekPlanning == false) {
                  if (this.calendarOptions.events != null) {
                    this.calendarOptions.events.forEach((e) => {
                      if (e.type == "boite") {

                          if (
                            moment(e.start).add(1, 'day').format("YYYY-MM-DD") == moment(calendarApi.getDate().toString()).format("YYYY-MM-DD")
                          ) {
                            let start = moment(e.start).add(1, "week");
                            let end = moment(e.end).add(1, "week");
                            e.start = start.format("YYYY-MM-DDTHH:mm:ss");
                            e.end = end.format("YYYY-MM-DDTHH:mm:ss");
                          }
                        
                      }
                    });
                  }
                } else {
                  if (this.calendarOptions.events != null) {
                    this.calendarOptions.events.forEach((e) => {
                      if (e.type == "boite") {
                        let start = moment(e.start).add(1, "week");
                        let end = moment(e.end).add(1, "week");
                        e.start = start.format("YYYY-MM-DDTHH:mm:ss");
                        e.end = end.format("YYYY-MM-DDTHH:mm:ss");
                      }
                    });
                  }
                }

            },
          },

          today: {
            // test
            text: "Aujourd'hui",
            click: () => {
              this.todayBtnActive = false;
              this.nextBtnPress = false;
              let calendarApi = this.$refs.fullCalendar.getApi();
              if (this.calendarOptions.events != null) {
                this.calendarOptions.events = [];
                let today = new Date();
                let diff =
                  today.getDate() -
                  today.getDay() +
                  (today.getDay() === 0 ? -6 : 1);
                let monday = new Date(today.setDate(diff));
                let date = moment(monday).format("YYYY-MM-DDTHH:mm:ss");
                if(this.dayWeekPlanning == false){
                  this.$emit("getMoniteursChange", moment(new Date()).format("YYYY-MM-DDT00:00:00"), false);
                }else{
                  this.$emit("getMoniteursChange", date, true);
                }
              }

              calendarApi.today();
            },
          },

          resourceTimeGridDay: {
            // this overrides the next button
            text: "Jour",
            click: () => {
              let calendarApi = this.$refs.fullCalendar.getApi();
              calendarApi.changeView("resourceTimeGridDay");
              calendarApi.today();
              this.dayWeekPlanning = false;
              this.todayBtnActive = true;
              this.nextBtnPress = false;
              //this.$emit("getMoniteursChange", moment(new Date()).format("YYYY-MM-DDT00:00:00"), false);
              if(this.calendarOptions.events != null){
                this.calendarOptions.events = [];
                let today = new Date();
                let diff =
                  today.getDate() -
                  today.getDay() +
                  (today.getDay() === 0 ? -6 : 1);
                let monday = new Date(today.setDate(diff));
                let date = moment(monday).format("YYYY-MM-DDTHH:mm:ss");
                if(this.dayWeekPlanning == false){
                  this.$emit("getMoniteursChange", moment(new Date()).format("YYYY-MM-DDT00:00:00"), false);
                }else{
                  this.$emit("getMoniteursChange", date, true);
                }
              }
            },
          },

          resourceTimeGridWeek: {
            // this overrides the next button
            text: "Semaine",
            click: () => {
              let calendarApi = this.$refs.fullCalendar.getApi();
              calendarApi.changeView("resourceTimeGridWeek");
              calendarApi.today();
              this.dayWeekPlanning = true;
              this.todayBtnActive = true;
              this.nextBtnPress = false;
              if(this.calendarOptions.events != null){
                this.calendarOptions.events = [];
                let today = new Date();
                let diff =
                  today.getDate() -
                  today.getDay() +
                  (today.getDay() === 0 ? -6 : 1);
                let monday = new Date(today.setDate(diff));
                let date = moment(monday).format("YYYY-MM-DDTHH:mm:ss");
                if(this.dayWeekPlanning == false){
                  this.$emit("getMoniteursChange", moment(new Date()).format("YYYY-MM-DDT00:00:00"), false);
                }else{
                  this.$emit("getMoniteursChange", date, true);
                }
              }
            },
          },
        },
        headerToolbar: {
          start: "prev,next today",
          center: "title",
          end: "resourceTimeGridWeek,resourceTimeGridDay,hideSunday",
        },

        buttonText: {
          today: "Aujourd'hui",
          resourceTimeGridDay: "Jour",
          resourceTimeGridWeek: "Semaine",
          prev: "<",
          next: ">",
        },
        resources: [],
        resourceOrder: "title",
        selectAllow: this.selectAllow,
        select: this.selectEvent,
        eventClick: this.deleteEvent,
        eventDidMount: this.eventhover,
        events: [],
        hiddenDays: [],
      },
      moniteurchoix: [],
      moniteursList: [],
      loadCalendar: 0,
      planningResaE: null,
      resaHeader: "",
      lieuPrevAp: [],
      eleveChoixResa: null,
      eleveChoixResaMonoSelect: false,
      loadCancelResa: false,
      dayWeekPlanning: true,
      todayBtnActive : true,
      nextBtnPress : false,
    };
  },

  mounted() {
    //this.getFonctionnalite();
    this.putOnPlanningApointment();
    this.choixMonoSaves();
    this.countElevesList = this.countEleves;
    this.lesEleves = this.eleves;
  },

  methods: {
    onPaginate(e) {
      let offset = e.first;
      window.api
        .get(
          "/eleves/agence/planning/" +
            this.$store.state.user.agence.id +
            "/offset/" +
            offset,
          {
            headers: {
              Authorization: "Bearer " + this.$store.state.token,
            },
          }
        )
        .then((response) => {
          this.lesEleves = response.data.eleves;
          this.countElevesList = response.data.count;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    hideSundayMethod(e) {
      if (this.hideSundayBtn) {
        this.hideSundayBtn = false;
        e.target.style.background = "#2196f3";
        this.calendarOptions.hiddenDays = [];
      } else {
        this.hideSundayBtn = true;
        this.calendarOptions.hiddenDays = [0];
        e.target.style.background = "black";
      }
    },

    getLieuEleve(eleve) {
      this.eleveChoixResa = eleve;
      if (eleve.choixDispo != "null") {
        this.lieuxDeb.forEach((l) => {
          eleve.choixDispo[0].choix.forEach((c) => {
            if (c.id == this.monoChoixResa) {
              this.eleveChoixResaMonoSelect = true;
              if (c.lieux != undefined) {
                c.lieux.forEach((li) => {
                  if (l.id == li) {
                    l.choixEleve = true;
                  }
                });
              }
            }
          });
        });
      }
    },

    eventhover(info) {
      info.el.title = info.event.title;
    },

    getLesMoniteurs() {
      this.$emit("getMoniteurs");
    },

    choixMonoSaves() {
      window.api
        .get("/agence/choix/" + this.$store.state.user.id, {
          headers: {
            Authorization: "Bearer " + this.$store.state.token,
          },
        })
        .then((response) => {
          if (response.data.choix.length != 0) {
            this.$store.commit("setChoixSaveMono", response.data.choix[0]);
            this.choixSaveMono = response.data.choix[0];
            this.calendarOptions.resources = this.choixSaveMono.choix;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    diff_hours(dt2, dt1) {
      let diff = (dt2.getTime() - dt1.getTime()) / 1000;
      diff /= 60 * 60;
      return Math.abs(Math.round(diff));
    },

    setMotif() {
      this.loadCancelResa = true;
      window.api
        .post(
          "/cancel/apointment/" +
            this.motif.leTypeMotif.id +
            "/" +
            this.planningResaE["event"]["_def"]["publicId"] +
            "/" +
            this.$store.state.user.id,
          {
            com: this.motif.com,
          },
          {
            headers: {
              Authorization: "Bearer " + this.$store.state.token,
            },
          }
        )
        .then((response) => {
          this.loadCancelResa = false;
          this.$emit("getEleves");
          this.popupMotif = false;

          if (response.data.apointment.typeMotif == "\tMono Absent") {
            response.data.apointment.typeMotif =
              response.data.apointment.typeMotif = "Mono Absent";
          }

          if (response.data.apointment.typeMotif == "Mono Absent") {
            let find = null;

            this.calendarOptions.events.forEach((a) => {
              if (a.id == response.data.apointment.id) {
                find = a;
              }
            });

            let filter = [];

            this.calendarOptions.events.forEach((a) => {
              if (a.id != response.data.apointment.id) {
                filter.push(a);
              }
            });

            find.title = find.title + " - " + response.data.apointment.motif;
            find.motif = response.data.apointment.motif;
            find.color = "maroon";
            filter.push(find);

            this.calendarOptions.events = filter;
          } else if (response.data.apointment.typeMotif == "Incident") {
            let find = null;
            this.calendarOptions.events.forEach((a) => {
              if (a.id == response.data.apointment.id) {
                find = a;
              }
            });

            let filter = [];

            this.calendarOptions.events.forEach((a) => {
              if (a.id != response.data.apointment.id) {
                filter.push(a);
              }
            });

            find.title = find.title + " - " + response.data.apointment.motif;
            find.motif = response.data.apointment.motif;
            find.color = "yellow";
            (find.textColor = "black"), filter.push(find);

            this.calendarOptions.events = filter;
          } else if (response.data.apointment.typeMotif == "Autre") {
            let filter = [];

            this.calendarOptions.events.forEach((a) => {
              if (a.id != response.data.apointment.id) {
                filter.push(a);
              }
            });
            this.calendarOptions.events = filter;
          } else if (response.data.apointment.typeMotif == "Eleve Absent") {
            let hDiff = this.diff_hours(
              new Date(response.data.apointment.start.date),
              new Date(response.data.apointment.cancelDate.date)
            );
            let find = null;

            this.calendarOptions.events.forEach((a) => {
              if (a.id == response.data.apointment.id) {
                find = a;
              }
            });

            let filter = [];

            this.calendarOptions.events.forEach((a) => {
              if (a.id != response.data.apointment.id) {
                filter.push(a);
              }
            });
            if (hDiff < 48) {
              find.title = find.title + " - " + response.data.apointment.motif;
              find.motif = response.data.apointment.motif;
              find.color = "red";
              filter.push(find);
              this.calendarOptions.events = filter;
            } else {
              this.calendarOptions.events = filter;
            }
          }

          this.leTypeMotif = null;
          this.com = "";

          window.api
            .post(
              "/sendMail",
              {
                type: "rdv annule",
                id: response.data.apointment.id,
                email: response.data.apointment.eleve.email,
              },
              {
                headers: {
                  Authorization: "Bearer " + this.$store.state.token,
                },
              }
            )
            .then(() => {})
            .catch((error) => {
              console.log(error);
            });

          if (
            response.data.apointment.typeMotif != "Eleve Absent" &&
            response.data.apointment.typeMotif != "Autre" &&
            response.data.apointment.typeMotif != "Incident"
          ) {
            window.location.reload();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    putOnPlanningApointment() {
      if (this.calendarOptions.events.length != 0) {
        let filterEvent = this.calendarOptions.events.filter(
          (e) => e.type != "resa"
        );
        this.calendarOptions.events = filterEvent;
      }
      this.$store.state.moniteursPlanningAgence.forEach((e) => {
        e.apointments.forEach((r) => {
          if (r.lieuFin.nom == "Cer impérativement") {
            let fin = r.lieuFin.nom.substring(3);
            r.lieuFin.nom = this.$store.state.user.agence.nom + fin;
          } else if (
            r.lieuFin.nom == "CER ou lieu début selon les besoins de l'agence"
          ) {
            let fin = r.lieuFin.nom.substring(18);
            r.lieuFin.nom =
              this.$store.state.user.agence.nom +
              " ou " +
              r.lieuDeb.nom +
              " " +
              fin;
          }

          let statAac = "";

          if (r.statutAac != "null") {
            statAac = r.statutAac;
          }

          if (r.motif == "null") {
            if (r.done) {
              this.calendarOptions.events.push({
                id: r.id,
                title:
                  statAac +
                  " " +
                  r.eleve.fullname +
                  "  deb : " +
                  r.lieuDeb.nom +
                  "/ fin :" +
                  r.lieuFin.nom,
                start: moment(r.start.date).format("YYYY-MM-DDTHH:mm:ss"),
                end: moment(r.end.date).format("YYYY-MM-DDTHH:mm:ss"),
                color: "teal",
                type: "resa",
                done: true,
                editable: false,
                motif: false,
                eleveId: r.eleve.id,
                resourceId: r.moniteur.idMoni,
              });
            } else {
              let color = "green";

              if (r.eleve.boite == "BM") {
                color = "#ED7742";
              }

              this.calendarOptions.events.push({
                id: r.id,
                title:
                  statAac +
                  " " +
                  r.eleve.fullname +
                  "  deb : " +
                  r.lieuDeb.nom +
                  "/ fin :" +
                  r.lieuFin.nom,
                start: moment(r.start.date).format("YYYY-MM-DDTHH:mm:ss"),
                end: moment(r.end.date).format("YYYY-MM-DDTHH:mm:ss"),
                color: color,
                type: "resa",
                done: false,
                editable: false,
                motif: false,
                eleveId: r.eleve.id,
                resourceId: r.moniteur.idMoni,
              });
            }
          } else {
            if (r.typeMotif == "Eleve Absent") {
              let hDiff = this.diff_hours(
                new Date(r.start.date),
                new Date(r.cancelRdv.date)
              );

              if (hDiff <= 48) {
                this.calendarOptions.events.push({
                  id: r.id,
                  title:
                    statAac +
                    " " +
                    r.eleve.fullname +
                    "  deb : " +
                    r.lieuDeb.nom +
                    "/ fin :" +
                    r.lieuFin.nom,
                  start: moment(r.start.date).format("YYYY-MM-DDTHH:mm:ss"),
                  end: moment(r.end.date).format("YYYY-MM-DDTHH:mm:ss"),
                  color: "red",
                  type: "resa",
                  done: false,
                  editable: false,
                  motif: false,
                  eleveId: r.eleve.id,
                  resourceId: r.moniteur.idMoni,
                });
              }
            } else if (r.typeMotif == "Mono Absent") {
              this.calendarOptions.events.push({
                id: r.id,
                title:
                  statAac +
                  " " +
                  r.eleve.fullname +
                  "  deb : " +
                  r.lieuDeb.nom +
                  "/ fin :" +
                  r.lieuFin.nom,
                start: moment(r.start.date).format("YYYY-MM-DDTHH:mm:ss"),
                end: moment(r.end.date).format("YYYY-MM-DDTHH:mm:ss"),
                color: "maroon",
                type: "resa",
                done: false,
                editable: false,
                eleveId: r.eleve.id,
                motif: false,
                resourceId: r.moniteur.idMoni,
              });
            } else if (r.typeMotif == "Incident") {
              this.calendarOptions.events.push({
                id: r.id,
                title:
                  statAac +
                  " " +
                  r.eleve.fullname +
                  "  deb : " +
                  r.lieuDeb.nom +
                  "/ fin :" +
                  r.lieuFin.nom,
                start: moment(r.start.date).format("YYYY-MM-DDTHH:mm:ss"),
                end: moment(r.end.date).format("YYYY-MM-DDTHH:mm:ss"),
                color: "yellow",
                type: "resa",
                done: false,
                textColor: "black",
                editable: false,
                eleveId: r.eleve.id,
                motif: false,
                resourceId: r.moniteur.idMoni,
              });
            }
          }
        });

        e.indispo.forEach((i) => {
          this.calendarOptions.events.push({
            id: i.id,
            title: "INDISPO",
            start: moment(i.start.date).format("YYYY-MM-DDTHH:mm:ss"),
            end: moment(i.end.date).format("YYYY-MM-DDTHH:mm:ss"),
            color: "grey",
            type: "indispo",
            motif: false,
            resourceId: i.moniteur.idMoni,
          });
        });

        if (this.todayBtnActive == true) {
          e.params.forEach((i) => {
            let today = new Date();
            let todayDay = today.getDay();
            let startDate = new Date(i.start);
            let endDate = new Date(i.end);
            let startDay = startDate.getDay();
            let endDay = endDate.getDay();
            let diffStart =
              today.getDate() - todayDay + (todayDay == 0 ? -6 : startDay); // permet de récupérer un jour précis de la semaine actuel en mettant l'id du jour (0 = dimanche 1 = lundi...)
            let diffEnd =
              today.getDate() - todayDay + (todayDay == 0 ? -6 : endDay); // permet de récupérer un jour précis de la semaine actuel en mettant l'id du jour (0 = dimanche 1 = lundi...)

            startDate = new Date(today.setDate(diffStart));
            endDate = new Date(today.setDate(diffEnd));

            if (startDay == 0) {
              startDate = startDate.setDate(startDate.getDate() + 1 * 7);
              startDate = new Date(startDate);
            }

            if (endDay == 0) {
              endDate = endDate.setDate(endDate.getDate() + 1 * 7);
              endDate = new Date(endDate);
            }

            let ddStart = String(startDate.getDate()).padStart(2, "0");
            let ddEnd = String(endDate.getDate()).padStart(2, "0");
            let mm = String(today.getMonth() + 1).padStart(2, "0");
            let yyyy = today.getFullYear();

            let start =
              yyyy + "-" + mm + "-" + ddStart + "T" + i.start.split("T")[1];
            let end = yyyy + "-" + mm + "-" + ddEnd + "T" + i.end.split("T")[1];

            if (i.title == "INDISPO") {
              this.calendarOptions.events.push({
                id: i.id,
                title: "INDISPO",
                start: moment(start).format("YYYY-MM-DDTHH:mm:ss"),
                end: moment(end).format("YYYY-MM-DDTHH:mm:ss"),
                color: "#494949FF",
                type: "boite",
                motif: false,
                resourceId: e.idMono,
              });
            } else {
              this.calendarOptions.events.push({
                id: i.id,
                title: i.title,
                start: moment(start).format("YYYY-MM-DDTHH:mm:ss"),
                end: moment(end).format("YYYY-MM-DDTHH:mm:ss"),
                color: i.color,
                type: "boite",
                motif: false,
                resourceId: e.idMono,
                display: "background",
              });
            }
          });
        }
      });
    },

    getAllEleves(e) {
      if (e.target.checked) {
        this.showEleveDispo = false;
      } else {
        this.showEleveDispo = true;
      }
    },

    getLieuFin() {
      if (this.lieuDebChoix != null) {
        if (this.firstCours) {
          this.firstCoursFin.forEach((l) => {
            this.eleveChoixResa.choixDispo[0].choix.forEach((c) => {
              if (c.id == this.monoChoixResa) {
                if (c.lieux != undefined) {
                  c.lieux.forEach((li) => {
                    if (l.id == li) {
                      l.choixEleve = true;
                    }
                  });
                }
              }
            });
          });
          let lDeb = this.lieuxDeb.find((l) => l.id == this.lieuDebChoix);

          let lxfin = [];
          this.lieuxFin = this.firstCoursFin;
          this.lieuxFin.forEach((l) => {
            if (lDeb.groupe == 3) {
              if (l.groupe == 1 || l.groupe == 2 || l.groupe == 3) {
                lxfin.push(l);
              }
            } else {
              if (l.groupe == 1 || l.groupe == 2) {
                lxfin.push(l);
              }
            }
          });

          this.lieuxFin = lxfin;
        } else {
          window.api
            .post(
              "get/lieux/" + this.monoChoixResa + "/resa/" + this.lieuDebChoix,
              {
                start: moment(this.planningResaE.startStr).format(
                  "YYYY-MM-DDTHH:mm:ss"
                ),
                end: moment(this.planningResaE.endStr).format(
                  "YYYY-MM-DDTHH:mm:ss"
                ),
              },
              {
                headers: {
                  Authorization: "Bearer " + this.$store.state.token,
                },
              }
            )
            .then((response) => {
              response.data.fin.forEach((l) => {
                this.eleveChoixResa.choixDispo[0].choix.forEach((c) => {
                  if (c.id == this.monoChoixResa) {
                    if (c.lieux != undefined) {
                      c.lieux.forEach((li) => {
                        if (l.id == li) {
                          l.choixEleve = true;
                        }
                      });
                    }
                  }
                });
              });

              this.lieuxFin = response.data.fin;
            })
            .catch((error) => {
              console.log(error);
            });
        }
      }
    },

    displayDispo(eleve) {
      eleve.dispos.forEach((d) => {
        this.calendarOptions.events.push({
          id: d.id,
          title: "Dispo : " + d.lieu,
          start: moment(d.start.date).format("YYYY-MM-DDTHH:mm:ss"),
          end: moment(d.end.date).format("YYYY-MM-DDTHH:mm:ss"),
          color: "blue",
          type: "dispo",
        });
      });
    },

    changeDisplayApointment(display) {
      let dispos = [];
      let apointments = [];
      let indispos = [];
      let boite = [];

      this.calendarOptions.events.forEach((di) => {
        if (di.type == "dispo") {
          dispos.push(di);
        }

        if (di.type == "resa") {
          apointments.push(di);
        }

        if (di.type == "indispo") {
          indispos.push(di);
        }

        if (di.type == "boite") {
          boite.push(di);
        }
      });

      if (display) {
        this.calendarOptions.events = [];

        if (this.$store.state.eleveSelectAgence != null) {
          this.$store.state.eleveSelectAgence.choixDispo[0]["choix"].forEach(
            (e) => {
              dispos.forEach((d) => {
                this.calendarOptions.events.push({
                  id: d.id,
                  title: d.title,
                  start: d.start,
                  end: d.end,
                  color: d.color,
                  type: d.type,
                  display: "background",
                  resourceId: e.id,
                });
              });
            }
          );
        }

        apointments.forEach((r) => {
          this.calendarOptions.events.push({
            id: r.id,
            title: r.title,
            start: r.start,
            end: r.end,
            color: r.color,
            type: r.type,
            done: r.done,
            editable: r.editable,
            motif: r.motif,
            resourceId: r.resourceId,
          });
        });

        indispos.forEach((r) => {
          this.calendarOptions.events.push({
            id: r.id,
            title: r.title,
            start: r.start,
            end: r.end,
            color: r.color,
            type: r.type,
            resourceId: r.resourceId,
            motif: false,
          });
        });

        boite.forEach((d) => {
          if (d.title == "INDISPO") {
            this.calendarOptions.events.push({
              id: d.id,
              title: d.title,
              start: d.start,
              end: d.end,
              color: d.color,
              type: d.type,
              resourceId: d.resourceId,
              motif: false,
            });
          } else {
            this.calendarOptions.events.push({
              id: d.id,
              title: d.title,
              start: d.start,
              end: d.end,
              color: d.color,
              type: d.type,
              resourceId: d.resourceId,
              display: "background",
            });
          }
        });
      } else {
        this.calendarOptions.events = [];
        indispos.forEach((r) => {
          this.calendarOptions.events.push({
            id: r.id,
            title: r.title,
            start: r.start,
            end: r.end,
            color: r.color,
            type: r.type,
            resourceId: r.resourceId,
            motif: false,
          });
        });

        dispos.forEach((d) => {
          this.calendarOptions.events.push({
            id: d.id,
            title: d.title,
            start: d.start,
            end: d.end,
            color: "blue",
            type: d.type,
          });
        });

        apointments.forEach((r) => {
          this.calendarOptions.events.push({
            id: r.id,
            title: r.title,
            start: r.start,
            end: r.end,
            color: r.color,
            type: r.type,
            done: r.done,
            editable: r.editable,
            motif: r.motif,
            resourceId: r.resourceId,
            display: "background",
          });
        });
      }
    },

    getFonctionnalite() {
      window.api
        .get("/fonctionnalites/4", {
          headers: {
            Authorization: "Bearer " + this.$store.state.token,
          },
        })
        .then((response) => {
          this.prixHeure = response.data.montant;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    deleteEvent(e) {
      this.planningResaE = e;
      if (e["event"]["_def"]["extendedProps"]["type"] == "indispo") {
        window.api
          .delete("/indisponibilites/" + e["event"]["_def"]["publicId"], {
            headers: {
              Authorization: "Bearer " + this.$store.state.token,
            },
          })
          .then(() => {
            let events = [];

            this.calendarOptions.events.forEach((i) => {
              if (i.id != e["event"]["_def"]["publicId"]) {
                events.push(i);
              }
            });

            this.calendarOptions.events = events;
          })
          .catch((error) => {
            console.log("ERRR::", error.response.data);
          });
      } else if (e["event"]["_def"]["extendedProps"]["type"] == "dispo") {
        window.api
          .delete("/disponibilites/" + e["event"]["_def"]["publicId"], {
            headers: {
              Authorization: "Bearer " + this.$store.state.token,
            },
          })
          .then(() => {
            let events = this.calendarOptions.events.filter((i) => {
              i.id != e["_def"]["publicId"];
            });

            this.calendarOptions.events = events;
          })
          .catch((error) => {
            console.log("ERRR::", error.response.data);
          });
      } else if (
        e["event"]["_def"]["extendedProps"]["type"] == "resa" &&
        e["event"]["_def"]["extendedProps"]["motif"] == false &&
        e["event"]["_def"]["extendedProps"]["done"] == false
      ) {
        window.api
          .get("/type_motifs", {
            headers: {
              Authorization: "Bearer " + this.$store.state.token,
            },
          })
          .then((response) => {
            window.api
              .get(
                "/eleves/agence/planning/selec/" +
                  e["event"]["_def"]["extendedProps"]["eleveId"],
                {
                  headers: {
                    Authorization: "Bearer " + this.$store.state.token,
                  },
                }
              )
              .then((r) => {
                let eleve = r.data.eleves[0];
                this.eleveChoixResa = eleve;
                this.typeMotif = response.data["hydra:member"];
                this.popupMotif = true;
              })
              .catch((error) => {
                console.log(error);
              });
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },

    showEditModal() {
      let lieu = this.planningResaE["event"]["_def"]["title"].split("deb :");
      this.lieuPrevAp = lieu[1].split("/ fin :");
      this.monoChoixResa =
        this.planningResaE["event"]["_def"]["resourceIds"][0];
      window.api
        .post(
          "/get/lieux/" + this.monoChoixResa + "/resa",
          {
            start: this.planningResaE["event"]["_instance"]["range"]["start"],
            end: this.planningResaE["event"]["_instance"]["range"]["end"],
          },
          {
            headers: {
              Authorization: "Bearer " + this.$store.state.token,
            },
          }
        )
        .then((r) => {
          window.api
            .get(
              "/eleves/agence/planning/selec/" +
                this.planningResaE["event"]["_def"]["extendedProps"]["eleveId"],
              {
                headers: {
                  Authorization: "Bearer " + this.$store.state.token,
                },
              }
            )
            .then((response) => {
              this.lieuxDeb = r.data.deb;
              this.firstCours = r.data.first;
              this.firstCoursFin = r.data.fin;
              this.boiteResa = r.data.boite;
              let student = response.data.eleves[0];
              this.getLieuEleve(student);
              this.resaHeader =
                "Modification : " +
                this.planningResaE["event"]["_def"]["title"];
              this.resaPopupEleve = true;
            })
            .catch((error) => {
              console.log(error);
            });
        })
        .catch((error) => {
          console.log(error);
        });
    },

    cancelEleveChoix() {
      let filter = [];

      this.calendarOptions.events.forEach((a) => {
        if (a.type != "dispo") {
          filter.push(a);
        }
      });
      this.calendarOptions.events = filter;
    },

    putApointment() {
      this.loadModifBtn = true;
      window.api
        .put(
          "/apointment/" + this.planningResaE["event"]["_def"]["publicId"],
          {
            lieu: this.lieuDebChoix,
            lieuFin: this.lieuFinChoix,
          },
          {
            headers: {
              Authorization: "Bearer " + this.$store.state.token,
            },
          }
        )
        .then((response) => {
          let filter = [];

          this.calendarOptions.events.forEach((a) => {
            if (a.id != response.data.apointment.id) {
              filter.push(a);
            }
          });
          this.calendarOptions.events = filter;

          let color = "green";

          if (response.data.apointment.eleve.boite == "BM") {
            color = "#ED7742";
          }

          let statAac = "";

          if (response.data.apointment.statutAac != "null") {
            statAac = response.data.apointment.statutAac;
          }

          this.calendarOptions.events.push({
            id: response.data.apointment.id,
            title:
              statAac +
              " " +
              response.data.apointment.eleve.fullname +
              "  deb : " +
              response.data.apointment.lieuDeb.nom +
              "/ fin :" +
              response.data.apointment.lieuFin.nom,
            start: moment(response.data.apointment.start.date).format(
              "YYYY-MM-DDTHH:mm:ss"
            ),
            end: moment(response.data.apointment.end.date).format(
              "YYYY-MM-DDTHH:mm:ss"
            ),
            color: color,
            type: "resa",
            eleveId: response.data.apointment.eleve.id,
            done: false,
            editable: false,
            motif: false,
            resourceId: response.data.apointment.moniteur.idMoni,
          });

          this.popupMotif = false;
          this.resaPopupEleve = false;
          this.loadModifBtn = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    setApointment() {
      this.loadResaModalBtn = true;
      let eleve = null;
      if (this.$store.state.eleveSelectAgence != null) {
        eleve = this.$store.state.eleveSelectAgence.id;
      } else {
        eleve = this.idEleveChoixResa;
      }

      let cantSimu = false;

      window.api
        .post(
          "/eleves/cansimu/" + eleve,
          {
            start: moment(this.planningResaE.startStr).format(
              "YYYY-MM-DDTHH:mm:ss"
            ),
            mono: this.monoChoixResa,
          },
          {
            headers: {
              Authorization: "Bearer " + this.$store.state.token,
            },
          }
        )
        .then((response) => {
          cantSimu = response.data.eleves[0].nbRdv;

          if (cantSimu == false || cantSimu <= 0) {
            window.api
              .post(
                "/set/apointment/" +
                  eleve +
                  "/" +
                  this.monoChoixResa +
                  "/" +
                  this.lieuDebChoix +
                  "/" +
                  this.lieuFinChoix,
                {
                  start: moment(this.planningResaE.startStr).format(
                    "YYYY-MM-DDTHH:mm:ss"
                  ),
                  end: moment(this.planningResaE.endStr).format(
                    "YYYY-MM-DDTHH:mm:ss"
                  ),
                },
                {
                  headers: {
                    Authorization: "Bearer " + this.$store.state.token,
                  },
                }
              )
              .then((response) => {
                if (response.data.message == "Solde insufisant") {
                  alert("Le solde de l'élève selectioné est insufisant");
                  this.loadResaModalBtn = false;
                } else {
                  let color = "green";

                  if (response.data.boite == "BM") {
                    color = "#ED7742";
                  }

                  let statAac = "";

                  if (response.data.statutAac != "null") {
                    statAac = response.data.statutAac;
                  }

                  this.calendarOptions.events.push({
                    id: response.data.apointment,
                    title:
                      statAac +
                      " " +
                      response.data.eleve +
                      "  deb : " +
                      response.data.deb +
                      "/ fin :" +
                      response.data.fin,
                    start: moment(this.planningResaE.startStr).format(
                      "YYYY-MM-DDTHH:mm:ss"
                    ),
                    end: moment(this.planningResaE.endStr).format(
                      "YYYY-MM-DDTHH:mm:ss"
                    ),
                    color: color,
                    type: "resa",
                    done: false,
                    editable: false,
                    motif: false,
                    eleveId: eleve,
                    resourceId: this.monoChoixResa,
                  });
                }
                this.$emit("getEleves");
                if (this.$store.state.eleveSelectAgence != null) {
                  this.resaPopupEleve = false;
                } else {
                  this.resaModal = false;
                }
                this.lieuxDeb = [];
                this.lieuxFin = null;
                this.lieuFinChoix = null;
                this.loadResaModalBtn = false;
                this.idEleveChoixResa = null;

                window.api
                  .post(
                    "/sendMail",
                    {
                      type: "rdv ok",
                      id: response.data.apointment,
                      email: response.data.eleveMail,
                    },
                    {
                      headers: {
                        Authorization: "Bearer " + this.$store.state.token,
                      },
                    }
                  )
                  .then(() => {})
                  .catch((error) => {
                    console.log(error);
                  });
              })
              .catch((error) => {
                console.log(error);
              });
          } else {
            if (this.apSimu == false) {
              let alertMsg = "";
              if (cantSimu == "not") {
                alertMsg =
                  "Vous ne pouvez pas réserver un cours avec un moniteur avant que les 5 cours obligatoires sur simulateur pour cet élève soient effectués";
              } else {
                alertMsg =
                  "Cet élève doit faire 5h de simulateur minimum avant de pouvoir conduire avec un moniteur (" +
                  cantSimu +
                  "h restante)";
              }

              alert(alertMsg);

              if (this.$store.state.eleveSelectAgence != null) {
                this.resaPopupEleve = false;
              } else {
                this.resaModal = false;
              }

              this.lieuxDeb = [];
              this.lieuxFin = null;
              this.lieuFinChoix = null;
              this.loadResaModalBtn = false;
              this.idEleveChoixResa = null;
            } else {
              window.api
                .post(
                  "/set/apointment/" +
                    eleve +
                    "/" +
                    this.monoChoixResa +
                    "/" +
                    this.lieuDebChoix +
                    "/" +
                    this.lieuFinChoix,
                  {
                    start: moment(this.planningResaE.startStr).format(
                      "YYYY-MM-DDTHH:mm:ss"
                    ),
                    end: moment(this.planningResaE.endStr).format(
                      "YYYY-MM-DDTHH:mm:ss"
                    ),
                  },
                  {
                    headers: {
                      Authorization: "Bearer " + this.$store.state.token,
                    },
                  }
                )
                .then((response) => {
                  if (response.data.message == "Solde insufisant") {
                    alert("Le solde de l'élève selectioné est insufisant");
                    this.loadResaModalBtn = false;
                  } else {
                    let color = "green";

                    if (response.data.boite == "BM") {
                      color = "#ED7742";
                    }

                    let statAac = "";

                    if (response.data.statutAac != "null") {
                      statAac = response.data.statutAac;
                    }

                    this.calendarOptions.events.push({
                      id: response.data.apointment,
                      title:
                        statAac +
                        " " +
                        response.data.eleve +
                        "  deb : " +
                        response.data.deb +
                        "/ fin :" +
                        response.data.fin,
                      start: moment(this.planningResaE.startStr).format(
                        "YYYY-MM-DDTHH:mm:ss"
                      ),
                      end: moment(this.planningResaE.endStr).format(
                        "YYYY-MM-DDTHH:mm:ss"
                      ),
                      color: color,
                      type: "resa",
                      done: false,
                      editable: false,
                      motif: false,
                      eleveId: eleve,
                      resourceId: this.monoChoixResa,
                    });
                  }
                  this.$emit("getEleves");
                  if (this.$store.state.eleveSelectAgence != null) {
                    this.resaPopupEleve = false;
                  } else {
                    this.resaModal = false;
                  }
                  this.lieuxDeb = [];
                  this.lieuxFin = null;
                  this.lieuFinChoix = null;
                  this.loadResaModalBtn = false;
                  this.idEleveChoixResa = null;

                  window.api
                    .post(
                      "/sendMail",
                      {
                        type: "rdv ok",
                        id: response.data.apointment,
                        email: response.data.eleveMail,
                      },
                      {
                        headers: {
                          Authorization: "Bearer " + this.$store.state.token,
                        },
                      }
                    )
                    .then(() => {})
                    .catch((error) => {
                      console.log(error);
                    });
                })
                .catch((error) => {
                  console.log(error);
                });
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    selectEvent(e) {
      this.planningResaE = e;

      this.monoChoixResa = e["resource"]["_resource"]["id"];

      if (this.$store.state.planningSelectChoice == "INDISPO") {
        this.setIndispo(e);
      } else if (this.$store.state.planningSelectChoice == "DISPO") {
        this.setDispo(e);
      } else if (
        this.$store.state.planningSelectChoice == "RESA" &&
        !e.allDay
      ) {
        window.api
          .post(
            "/eleves/agence/dispo/" +
              this.$store.state.user.agence.id +
              "/offset/0",
            {
              start: moment(e.startStr).format("YYYY-MM-DDTHH:mm:ss"),
              end: moment(e.endStr).format("YYYY-MM-DDTHH:mm:ss"),
              mono: this.monoChoixResa,
            },
            {
              headers: {
                Authorization: "Bearer " + this.$store.state.token,
              },
            }
          )
          .then((response) => {
            response.data.eleves.forEach((eleve) => {
              this.eleveDispos.push(eleve);
            });
          })
          .catch((error) => {
            console.log(error);
          });

        window.api
          .post(
            "/get/lieux/" + this.monoChoixResa + "/resa",
            {
              start: moment(e.startStr).format("YYYY-MM-DDTHH:mm:ss"),
              end: moment(e.endStr).format("YYYY-MM-DDTHH:mm:ss"),
            },
            {
              headers: {
                Authorization: "Bearer " + this.$store.state.token,
              },
            }
          )
          .then((r) => {
            window.api
              .get("/prix/conduite", {
                headers: {
                  Authorization: "Bearer " + this.$store.state.token,
                },
              })
              .then((response) => {
                this.prixHeure = response.data;

                if (this.$store.state.eleveSelectAgence != null) {
                  this.eleveChoixResa = this.$store.state.eleveSelectAgence;
                  this.getLieuEleve(this.eleveChoixResa);
                  if (e["resource"]["_resource"]["title"] == "Simulateur") {
                    this.apSimu = true;
                  } else {
                    this.apSimu = false;
                  }

                  this.resaPopupEleve = true;
                  this.resaHeader =
                    moment(this.planningResaE.startStr).format(
                      "ddd DD/MM/YY - HH"
                    ) +
                    "h" +
                    moment(this.planningResaE.startStr).format("mm") +
                    " - " +
                    moment(this.planningResaE.endStr).format("HH") +
                    "h" +
                    moment(this.planningResaE.endStr).format("mm");
                } else {
                  this.resaModal = true;
                  this.resaHeader =
                    moment(this.planningResaE.startStr).format(
                      "ddd DD/MM/YY - HH"
                    ) +
                    "h - " +
                    moment(this.planningResaE.endStr).format("HH") +
                    "h - " +
                    "BA =" +
                    this.prixHeure.prixBa +
                    "€" +
                    " BM =" +
                    this.prixHeure.prixBm +
                    "€";
                }
              })
              .catch((error) => {
                console.log(error);
              });

            this.lieuxDeb = r.data.deb;
            this.firstCours = r.data.first;
            this.firstCoursFin = r.data.fin;
            this.boiteResa = r.data.boite;
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },

    setDispo(e) {
      window.api
        .post(
          "/disponibilites",
          {
            start: moment(e.startStr).format("YYYY-MM-DDTHH:mm:ss"),
            end: moment(e.endStr).format("YYYY-MM-DDTHH:mm:ss"),
            eleve: "api/utilisateurs/" + this.$store.state.eleveSelectAgence.id,
          },
          {
            headers: {
              Authorization: "Bearer " + this.$store.state.token,
            },
          }
        )
        .then((response) => {
          this.$emit("getEleves");
          this.calendarOptions.events.push({
            id: response.data.id,
            title: "Dispo" + response.data.lieu,
            start: moment(e.startStr).format("YYYY-MM-DDTHH:mm:ss"),
            end: moment(e.endStr).format("YYYY-MM-DDTHH:mm:ss"),
            color: "blue",
            type: "dispo",
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },

    setIndispo(e) {
      window.api
        .post(
          "/indisponibilites",
          {
            start: moment(e.startStr).format("YYYY-MM-DDTHH:mm:ss"),
            end: moment(e.endStr).format("YYYY-MM-DDTHH:mm:ss"),
            moniteur: "api/moniteurs/" + e["resource"]["_resource"]["id"],
          },
          {
            headers: {
              Authorization: "Bearer " + this.$store.state.token,
            },
          }
        )
        .then((response) => {
          this.calendarOptions.events.push({
            id: response.data.id,
            title: "INDISPO",
            start: moment(e.startStr).format("YYYY-MM-DDTHH:mm:ss"),
            end: moment(e.endStr).format("YYYY-MM-DDTHH:mm:ss"),
            color: "grey",
            type: "indispo",
            motif: false,
            resourceId: e["resource"]["_resource"]["id"],
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },

    moniteurChoicePlanning(moniteur, checked) {
      this.moniteursList = this.$store.state.moniteursPlanningAgence;
      if (checked) {
        this.moniteurchoix.push({
          title: moniteur.prenom,
          id: moniteur.idMono,
        });
        this.calendarOptions.resources = this.moniteurchoix;
        let moniteurFind = this.moniteursList.find(
          (mono) => mono.idMono == moniteur.idMono
        );
        moniteurFind.checked = true;
        let moniteursFilter = this.moniteursList.filter(
          (mono) => mono.idMono != moniteurFind.idMono
        );
        moniteursFilter.push(moniteurFind);
        this.moniteursList = moniteursFilter;
        this.$store.commit("setMoniteursPlanningAgence", this.moniteursList);
      } else {
        let filter = this.calendarOptions.resources.filter(
          (mono) => mono.id != moniteur.idMono
        );
        this.calendarOptions.resources = filter;
        this.moniteurchoix = filter;
        let moniteurFind = this.moniteursList.find(
          (mono) => mono.idMono == moniteur.idMono
        );
        moniteurFind.checked = false;
        let moniteursFilter = this.moniteursList.filter(
          (mono) => mono.idMono != moniteurFind.idMono
        );
        moniteursFilter.push(moniteurFind);
        this.moniteursList = moniteursFilter;
        this.$store.commit("setMoniteursPlanningAgence", this.moniteursList);
      }
    },

    selectAllow(select) {
      return moment().diff(select.start) <= 0;
    },
  },
};
</script>

<style scoped>
input[type="text"]#motifText {
  width: 100%;
  padding: 12px 20px;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

select.resaModalEleves {
  width: 40%;
  padding: 16px 20px;
  border: none;
  border-radius: 4px;
  margin-right: 4em;
  background-color: #f1f1f1;
}

select.selectResaEleve {
  width: 100%;
  padding: 16px 20px;
  border: none;
  border-radius: 4px;
  margin-bottom: 1em;
  background-color: #f1f1f1;
}

select.monoSelectResaEleves {
  padding: 8px 20px;
  border: none;
  border-radius: 4px;
  margin-bottom: 1em;
  background-color: #f1f1f1;
}

div.head {
  display: flex;
  justify-content: space-around;
}
.selectMono {
  background-color: rgba(19, 192, 19, 0.2);
}

button.btnValMotif {
  margin-right: 50%;
}
</style>
